import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

export default class ComponentTabs extends Component {
    render() {
        return (
            <Tabs>
                {this.props.tabs.map((tab) => {
                    const { tabTitle, TabComponent } = tab;
                    const tabKey = tabTitle.toLowerCase().replace(' ', '-');

                    return (
                        <Tab eventKey={tabKey} title={tabTitle} key={tabKey}>
                            <TabComponent docId={this.props.docId} />
                        </Tab>
                    );
                })}
            </Tabs>
        );
    }
}
