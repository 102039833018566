import React, { Component } from 'react';
import StudentForm from './StudentForm';
import { connect } from 'react-redux';
class PreExam extends Component {
    componentDidMount() {
        this.props.setShowCourseMenu(false);
    }
    render() {
        return (
            <StudentForm
                typeSlug='pre-exam'
                typeTitle='Pre Exam'
                courseId={this.props.match.params.courseId}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};

export default connect(null, mapDispatchToProps)(PreExam);
