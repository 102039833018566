import {
    Modal,
    Row,
    Col,
    FormGroup,
    Form,
    Button,
    Alert,
} from 'react-bootstrap';
import React, { Component } from 'react';
import './QuestionsModal.scss';
import Countdown from 'react-countdown';
import DownloadButton from './DownloadButton';

export default class QuestionsModal extends Component {
    state = {
        showTimeOut: false,
        showLeave: false,
    };

    handleTimeout = () => {
        this.setState({
            showTimeout: true,
        });
    };

    toggleLeave = () => {
        this.setState({
            showLeave: !this.state.showLeave,
        });
    };

    handlePause = () => {
        this.props.onPause();
        this.toggleLeave();
    };

    componentDidMount() {
        if (this.props.timeLimit < Date.now()) {
            this.handleTimeout();
        }
    }

    rand(items) {
        return items[(items.length * Math.random()) | 0];
    }

    render() {
        const { props } = this;

        return (
            <div>
                <Modal
                    className='questions-modal'
                    onHide={props.readOnly ? props.onHide : () => {}}
                    show={props.show}
                    size='xl'>
                    {props.title && (
                        <Modal.Title>
                            {props.readOnly && (
                                <b>
                                    Your last attempt&apos;s anwers&nbsp;-&nbsp;
                                </b>
                            )}
                            {props.title}
                        </Modal.Title>
                    )}
                    <Modal.Body>
                        <div>
                            {props.questions.map((question, questionIndex) => {
                                return (
                                    <Row
                                        key={questionIndex}
                                        className='pt-0 pb-2'>
                                        <Col>
                                            {props.readOnly && (
                                                <Alert
                                                    variant={
                                                        props.answers[
                                                            questionIndex
                                                        ] ===
                                                        question?.correctOptionIdx
                                                            ? 'success'
                                                            : 'warning'
                                                    }>
                                                    <Alert.Heading>
                                                        {props.answers[
                                                            questionIndex
                                                        ] ===
                                                        question?.correctOptionIdx
                                                            ? `Correct answer! ${this.rand(
                                                                  [
                                                                      '👍',
                                                                      '🙌',
                                                                      '👌',
                                                                      '😊',
                                                                      '😎',
                                                                      '😉',
                                                                      '👏',
                                                                  ]
                                                              )}`
                                                            : `Wrong answer ${this.rand(
                                                                  [
                                                                      '👎',
                                                                      '😟',
                                                                      '🤷‍♂️',
                                                                      '🤷‍♀️',
                                                                      '🤦‍♀️',
                                                                      '🤦‍♂️',
                                                                      '🤔',
                                                                  ]
                                                              )}`}
                                                    </Alert.Heading>
                                                    <div>
                                                        {props.answers[
                                                            questionIndex
                                                        ] ===
                                                        question?.correctOptionIdx
                                                            ? question.msgIfCorrect
                                                            : question.msgIfWrong}
                                                    </div>
                                                </Alert>
                                            )}
                                            <FormGroup>
                                                <Form.Label className='pb-2'>
                                                    {questionIndex + 1}.{' '}
                                                    {question.title}
                                                </Form.Label>
                                                {question.options.map(
                                                    (option, optionIndex) => {
                                                        return (
                                                            <Form.Check
                                                                key={
                                                                    optionIndex
                                                                }
                                                                id={option}
                                                                type='radio'
                                                                name={
                                                                    option.title
                                                                }
                                                                label={option}
                                                                className={{
                                                                    'questions-modal--option': true,
                                                                    'questions-modal--option__valid':
                                                                        optionIndex ===
                                                                            props
                                                                                .questions[
                                                                                questionIndex
                                                                            ]
                                                                                ?.correctOptionIdx &&
                                                                        props.readOnly,
                                                                    'questions-modal--option__chosen':
                                                                        props
                                                                            .answers[
                                                                            questionIndex
                                                                        ] ===
                                                                            optionIndex &&
                                                                        props.readOnly,
                                                                }}
                                                                onChange={
                                                                    !this.props
                                                                        .readOnly
                                                                        ? () =>
                                                                              props.onAnswer(
                                                                                  questionIndex,
                                                                                  optionIndex
                                                                              )
                                                                        : null
                                                                }
                                                                readOnly={
                                                                    props.readOnly
                                                                }
                                                                checked={
                                                                    props
                                                                        .answers[
                                                                        questionIndex
                                                                    ] ===
                                                                    optionIndex
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                                <hr></hr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            {props.error && (
                                <Alert variant='warning'>{props.error}</Alert>
                            )}
                        </div>
                        <div>
                            <div>
                                {
                                    props.answers.filter(
                                        (answer) => answer !== null
                                    ).length
                                }{' '}
                                / {props.questions.length} questions answered
                                {props.readOnly && (
                                    <span className='nobold'>
                                        ,{' '}
                                        {props.answers.reduce(
                                            (count, val, index) => {
                                                if (
                                                    props.questions[index]
                                                        .correctOptionIdx ===
                                                    val
                                                ) {
                                                    count++;
                                                }
                                                return count;
                                            },
                                            0
                                        )}{' '}
                                        correct,{' '}
                                        {props.answers.reduce(
                                            (count, val, index) => {
                                                if (
                                                    props.questions[index]
                                                        .correctOptionIdx !==
                                                    val
                                                ) {
                                                    count++;
                                                }
                                                return count;
                                            },
                                            0
                                        )}{' '}
                                        wrong
                                    </span>
                                )}
                            </div>
                            {!props.readOnly && (
                                <div>
                                    Time remaining:&nbsp;
                                    <Countdown
                                        date={props.timeLimit}
                                        onComplete={this.handleTimeout}
                                    />
                                </div>
                            )}
                            {props.readOnly ? (
                                <div>
                                    <DownloadButton
                                        attempt={this.props.attempt}
                                    />
                                    <Button
                                        className='bd'
                                        onClick={props.onHide}>
                                        Close
                                    </Button>
                                </div>
                            ) : (
                                <div className='qm-buttons'>
                                    <span onClick={this.toggleLeave}>
                                        Leave
                                    </span>
                                    <Button
                                        className={{
                                            bp:
                                                props.questions.length ===
                                                props.answers.length,
                                            bd:
                                                (props.answers.filter(
                                                    (answer) => answer !== null
                                                ).length !==
                                                    props.questions.length &&
                                                    !props.allowSkip) ||
                                                props.questions.length !==
                                                    props.answers.length,
                                        }}
                                        onClick={props.onSubmit}
                                        disabled={
                                            (props.answers.filter(
                                                (answer) => answer !== null
                                            ).length !==
                                                props.questions.length &&
                                                !props.allowSkip) ||
                                            props.questions.length !==
                                                props.answers.length
                                        }>
                                        Submit
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className='qm-timeout'
                    show={this.state.showTimeout}
                    onHide={() => {}}>
                    <Modal.Body>
                        <center>Your time has expired</center>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='bp' onClick={props.onSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className='qm-leave'
                    show={this.state.showLeave}
                    onHide={() => {}}>
                    <Modal.Body>
                        <center>
                            You are about to leave an ongoing quiz, are you sure
                            you want to proceed?
                        </center>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className='bp mr-auto'
                            onClick={this.handlePause}>
                            Save & resume later
                        </Button>
                        <Button className='bd' onClick={this.toggleLeave}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
