import React, { Component } from 'react';
import apiCall from '../../../helpers/apiCall';
import DocumentViewer from '../DocumentViewer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Form, FormGroup } from 'react-bootstrap';
import './StudentForm.scss';
class StudentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formInputs: [],
            userAnswers: [],
            firstChapterId: '',
            firstLessonId: '',
        };
        this.inputRefs = [];
    }

    componentDidMount = async () => {
        const courseId = this.props.courseId;
        const { success, response, message } = await apiCall(
            'GET',
            `/users/${this.props.typeSlug}/${courseId}`
        );

        if (success && 'fields' in response) {
            this.setState({
                formInputs: response.fields,
                userAnswers: response.fields.map((field, i) => {
                    return {
                        key: field.key,
                        response: '',
                    };
                }),
            });
        }

        const { success: foundChapters, response: chapters } = await apiCall(
            'GET',
            `/users/courses/${courseId}/chapters`
        );
        if (foundChapters) {
            const url = `/users/chapters/${chapters.chapters[0]._id}/lessons`;
            const { success: foundLessons, response: lessons } = await apiCall(
                'GET',
                url
            );
            if (foundLessons) {
                this.setState({
                    firstChapterId: chapters.chapters[0]._id,
                    firstLessonId: lessons.lessons[0]._id,
                });
            }
        }
    };

    setInputRef = (i, element) => {
        if (element && this.inputRefs.indexOf(element) < 0) {
            this.inputRefs.push(element);
        }
    };

    handleChange = (i, event) => {
        let userAnswers = [...this.state.userAnswers];
        userAnswers[i].response = event.target.value;
        this.setState({
            userAnswers,
        });
    };

    handleMulticheckboxChange = (i, event, response) => {
        let userAnswers = [...this.state.userAnswers];
        if (!userAnswers[i].response) {
            userAnswers[i].response = [];
        }
        if (event.target.checked) {
            userAnswers[i].response.push(response);
        } else {
            userAnswers[i].response = userAnswers[i].response.filter((res) => {
                return res !== response;
            });
        }
        this.setState({
            userAnswers,
        });
    };

    handleRadioChange = (i, opt) => {
        let userAnswers = [...this.state.userAnswers];
        userAnswers[i].response = opt;
        this.setState({
            userAnswers,
        });
    };

    handleFileChange = (i, event) => {
        if (event.target.value) {
            let userAnswers = [...this.state.userAnswers];
            userAnswers[i].response = event.target.files[0];
            this.setState({
                userAnswers,
            });
        }
    };

    uploadFiles = async () => {
        const uploadPromises = this.state.userAnswers.map((answer, i) => {
            if (
                this.state.formInputs[i].inputType === 'file' &&
                answer.response
            ) {
                let filePostData = new FormData();
                filePostData.append('file', answer.response);
                return apiCall('POST', '/file', filePostData).then(
                    ({ success, response }) => {
                        if (success) {
                            let userAnswers = [...this.state.userAnswers];
                            userAnswers[i].response = response.url;
                            this.setState({
                                userAnswers,
                            });
                        }
                    }
                );
            }
        });

        await Promise.all(uploadPromises);
    };

    checkValidity = (e) => {
        let allValid = true;
        for (let i = 0; i < this.inputRefs.length; i++) {
            const ref = this.inputRefs[i];
            if (ref && !ref.checkValidity()) {
                allValid = false;
                ref.reportValidity();
                break;
            }
        }

        return allValid;
    };

    submitForm = async (e) => {
        const isValid = this.checkValidity(e);
        if (isValid) {
            e.preventDefault();
            await this.uploadFiles();
            const { typeSlug, courseId } = this.props;
            const { success } = await apiCall(
                'POST',
                `/users/${typeSlug}/${courseId}`,
                this.state.userAnswers
            );
            if (success) {
                if (typeSlug == 'enrollment') {
                    this.props.history.push(
                        `/user/course/${courseId}/chapters/${this.state.firstChapterId}/lesson/${this.state.firstLessonId}`
                    );
                    this.props.setNewCoursePopUp(true);
                } else if (typeSlug == 'pre-exam') {
                    this.props.history.push(`/user/exam/${courseId}`);
                } else if (typeSlug == 'post-exam') {
                    this.props.history.push(`/user/certificate/${courseId}`);
                }
            }
        }
    };

    render() {
        return (
            <Container fluid style={{ padding: 0 }}>
                <div className='title-banner'>
                    <Col>{this.props.typeTitle}</Col>
                </div>
                {this.state.formInputs &&
                    this.state.formInputs.map((input, i) => {
                        return (
                            <Row key={i}>
                                <Col md={9}>
                                    <FormGroup style={{ margin: '0 10px' }}>
                                        <Form.Label htmlFor={input.label}>
                                            {input.label}
                                        </Form.Label>
                                        {input.inputType === 'text' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                type='text'
                                                required={input.required}
                                                id={input.label}
                                                name={input.key}
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'number' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                type='number'
                                                required={input.required}
                                                id={input.label}
                                                name={input.key}
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'date' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                type='date'
                                                required={input.required}
                                                id={input.label}
                                                name={input.key}
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'file' && (
                                            <Form.File
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                required={input.required}
                                                accept={
                                                    input.extra.allowedFileTypes
                                                }
                                                id={input.label}
                                                name={input.key}
                                                onChange={(event) => {
                                                    this.handleFileChange(
                                                        i,
                                                        event
                                                    );
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'typeDNA' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                type='text'
                                                required={input.required}
                                                id={input.label}
                                                name={input.key}
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'textarea' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                as='textarea'
                                                type='text'
                                                required={input.required}
                                                id={input.label}
                                                name={input.key}
                                                rows='4'
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}
                                            />
                                        )}
                                        {input.inputType === 'options' && (
                                            <Form.Control
                                                ref={(element) => {
                                                    this.setInputRef(
                                                        i,
                                                        element
                                                    );
                                                }}
                                                as='select'
                                                required={input.required}
                                                value={
                                                    this.state.userAnswers[i]
                                                        .response
                                                }
                                                onChange={(event) => {
                                                    this.handleChange(i, event);
                                                }}>
                                                <option
                                                    disabled
                                                    value=''></option>
                                                {input.extra.options.map(
                                                    (opt, i) => {
                                                        return (
                                                            <option
                                                                key={i}
                                                                value={opt}>
                                                                {opt}
                                                            </option>
                                                        );
                                                    }
                                                )}
                                            </Form.Control>
                                        )}
                                        {input.inputType === 'checkbox' && (
                                            <div>
                                                {input.extra.options.map(
                                                    (opt, j) => {
                                                        return (
                                                            <Form.Check
                                                                key={j}
                                                                type='checkbox'
                                                                label={opt}
                                                                required={
                                                                    input.required
                                                                }
                                                                checked={
                                                                    this.state.userAnswers[
                                                                        i
                                                                    ].response.indexOf(
                                                                        opt
                                                                    ) >= 0
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.handleMulticheckboxChange(
                                                                        i,
                                                                        event,
                                                                        opt
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                        {input.inputType === 'radio' && (
                                            <div>
                                                {input.extra.options.map(
                                                    (opt, j) => {
                                                        return (
                                                            <Form.Check
                                                                key={j}
                                                                type='radio'
                                                                name={
                                                                    input.label
                                                                }
                                                                label={opt}
                                                                required={
                                                                    input.required
                                                                }
                                                                checked={
                                                                    this.state
                                                                        .userAnswers[
                                                                        i
                                                                    ]
                                                                        .response ===
                                                                    opt
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.handleRadioChange(
                                                                        i,
                                                                        opt
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )}
                                            </div>
                                        )}
                                        {input.inputType === 'textBlock' && (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: input.extra.copy,
                                                }}></div>
                                        )}
                                        {input.inputType === 'document' && (
                                            <DocumentViewer
                                                document={input.extra.sourceUrl}
                                            />
                                        )}
                                        {input.inputType === 'image' && (
                                            <div>
                                                <img
                                                    src={input.extra.sourceUrl}
                                                    alt='image'
                                                    height='300'></img>
                                            </div>
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        );
                    })}
                <Row className='pt-5'>
                    <Col>
                        <button
                            className='bp d-block'
                            style={{ margin: '0 auto' }}
                            type='button'
                            onClick={this.submitForm}>
                            Submit {this.props.typeTitle} Form
                        </button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { newCoursePopUp } = state; // inside curly braces we have the name of the state we want
    return {
        newCoursePopUp,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setNewCoursePopUp: (payload) => {
            dispatch({
                type: 'SET_NEW_COURSE_POP_UP',
                payload,
            });
        },
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StudentForm));
