import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import {
    setUserInactive,
    setLastSeen,
} from '../../../store/actions/userActions';
// tracking every one of their moves 👓

//TODO need to track lesson changes and current lesson user is on. Will use redux
import { connect } from 'react-redux';

class UserTracker extends Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.state = {
            viewingLesson: true,
        };
    }

    handleOnAction(event) {
        if (event.type == 'visibilitychange') {
            if (this.state.viewingLesson) {
                this.props.setUserInactive(true);
                this.setState({ viewingLesson: false });
                this.props.setLastSeen();
            } else {
                this.props.setUserInactive(false);
                this.setState({ viewingLesson: true });
                this.props.setLastSeen();
            }
        } else {
            this.props.setUserInactive(false);
        }
    }

    handleOnActive(event) {
        this.props.setUserInactive(false);
    }

    handleOnIdle(event) {
        if (this.props.inactiveTime > 0) {
            this.props.setUserInactive(true);
        }
        this.props.setLastSeen();
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    timeout={this.props.inactiveTime * 1000}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { userInactive } = state;
    return {
        userInactive,
    };
};

const mapDispatchToProps = {
    setUserInactive,
    setLastSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTracker);
