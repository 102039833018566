import React from 'react';
import { useHistory } from 'react-router-dom';
import apiCall from '../../../helpers/apiCall';
import './CourseCard.scss';

export const CourseCard = ({
    image,
    title,
    description,
    course,
    courseProgress,
}) => {
    const history = useHistory();
    const getChaptersPage = async () => {
        if (course.latestChapter != null && course.latestLesson != null) {
            history.push(
                `/user/course/${course._id}/chapters/${course.latestChapter}/lesson/${course.latestLesson}`
            );
        } else {
            const {
                success: foundChapters,
                response: chapters,
            } = await apiCall('GET', `/users/courses/${course._id}/chapters`);
            if (foundChapters) {
                const url = `/users/chapters/${chapters.chapters[0]._id}/lessons`;
                const {
                    success: foundLessons,
                    response: lessons,
                } = await apiCall('GET', url);
                if (foundLessons) {
                    history.push(
                        `/user/course/${course._id}/chapters/${chapters.chapters[0]._id}/lesson/${lessons.lessons[0]._id}`
                    );
                }
            }
        }
    };

    const getEnrollmentPage = () => {
        history.push(`/user/enroll/${course._id}`);
    };

    const getPreExam = () => {
        history.push(`/user/preexam/${course._id}`);
    };

    const enrollmentDate =
        'enrollmentUpdatedAt' in course
            ? new Date(course.enrollmentUpdatedAt).toLocaleDateString('en-US')
            : '';

    const handleOnClick = () => {
        course.status == 'FINISHED' || course.courseProgress >= 100
            ? getPreExam()
            : 'enrollmentUpdatedAt' in course
            ? getChaptersPage()
            : getEnrollmentPage();
    };

    return (
        <div
            className='xcc'
            onClick={handleOnClick}
            style={{ cursor: 'pointer' }}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`,
                }}>
                <div className='xccp'>
                    <span>{course.courseProgress}%</span>
                </div>
            </div>
            <div>
                <h2>{title}</h2>
                <div className='xccd'>
                    <span>
                        {enrollmentDate.length > 0
                            ? 'Enrolled: ' + enrollmentDate
                            : ''}
                    </span>
                </div>
                <p>{description}</p>
            </div>
            <div>
                <button className='bp' onClick={handleOnClick}>
                    {course.status == 'FINISHED' || course.courseProgress >= 100
                        ? 'Take Exam'
                        : 'enrollmentUpdatedAt' in course &&
                          course.status == 'NEW'
                        ? 'Resume'
                        : 'enrollmentUpdatedAt' in course &&
                          course.status == 'INCOMPLETE'
                        ? 'Resume'
                        : course.status == 'EXAM_PASSED' ||
                          course.status == 'FAILED'
                        ? 'Results'
                        : 'Enroll'}
                </button>
            </div>
        </div>
    );
};
