import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import apiCall from '../../../helpers/apiCall';
import '../../../components/ApiTable/ApiTable.scss';
import { Spinner } from '../../../components/Spinner';

class UserProgress extends Component {
    state = {
        user: null,
        chapters: [],
        loading: true,
    };

    async componentDidMount() {
        const { success: successUser, response: responseUser } = await apiCall(
            'GET',
            '/users/' + this.props.match.params.userId
        );

        if (successUser) {
            this.setState({
                user: responseUser,
            });

            this.props.pushBreadcrumbLink({
                text: `${responseUser.firstName} ${responseUser.lastName}`,
                path: '/admin/users/' + this.props.match.params.userId,
            });

            this.props.pushBreadcrumbLink({
                text: 'Progress',
                path: `/admin/users/${this.props.match.params.userId}/course/${this.props.match.params.courseId}/progress`,
            });
        }

        const { success, response } = await apiCall(
            'GET',
            `/users/${this.props.match.params.userId}/courses/${this.props.match.params.courseId}/chapters`
        );

        let chapters = response.chapters.map(async (chapter) => {
            const { success, response } = await apiCall(
                'GET',
                `/users/${this.props.match.params.userId}/chapters/${chapter._id}/lessons`
            );
            chapter.lessons = response.lessons;
            return chapter;
        });

        chapters = await Promise.all(chapters);

        this.setState({
            chapters,
            loading: false,
        });
    }

    componentWillUnmount() {
        this.props.removeBreadcrumbLink({
            text: 'Progress',
            path: `/admin/users/${this.props.match.params.userId}/course/${this.props.match.params.courseId}/progress`,
        });

        this.props.removeBreadcrumbLink({
            text: `${this.state.user.firstName} ${this.state.user.lastName}`,
            path: '/admin/users/' + this.props.match.params.userId,
        });
    }

    render() {
        if (this.state.loading) {
            return <Spinner />;
        } else {
            return (
                <div className='table-wrapper'>
                    <div className='table'>
                        <header>
                            <div className='col col-4'>Chapter</div>
                            <div className='col col-4'>Lesson</div>
                            <div className='col col-1'>Status</div>
                            <div className='col col-1'>
                                Required time (mins)
                            </div>
                            <div className='col col-1'>Time spent (mins)</div>
                            <div className='col col-1'>Actions</div>
                        </header>
                        <main>
                            {this.state.chapters.map((chapter, i) => {
                                return chapter.lessons.map((lesson, j) => {
                                    return (
                                        <div key={`${i}-${j}`} className='row'>
                                            <div
                                                style={{
                                                    minWidth: 'auto',
                                                    maxWidth: 'auto',
                                                }}
                                                className='col col-4'>
                                                {chapter.title.length > 50
                                                    ? chapter.title.substr(
                                                          0,
                                                          50
                                                      ) + '...'
                                                    : chapter.title}
                                            </div>
                                            <div
                                                style={{
                                                    minWidth: 'auto',
                                                    maxWidth: 'auto',
                                                }}
                                                className='col col-4'>
                                                {lesson.title.length > 50
                                                    ? lesson.title.substr(
                                                          0,
                                                          50
                                                      ) + '...'
                                                    : lesson.title}
                                            </div>
                                            <div
                                                style={{
                                                    minWidth: 'auto',
                                                    maxWidth: 'auto',
                                                }}
                                                className='col col-1'>
                                                {lesson.completed
                                                    ? 'Completed'
                                                    : lesson.unlocked
                                                    ? 'Unlocked'
                                                    : 'Locked'}
                                            </div>
                                            <div
                                                style={{
                                                    minWidth: 'auto',
                                                    maxWidth: 'auto',
                                                }}
                                                className='col col-1'>
                                                {lesson.requiredTime}
                                            </div>
                                            <div
                                                style={{
                                                    minWidth: 'auto',
                                                    maxWidth: 'auto',
                                                }}
                                                className='col col-1'>
                                                {Math.floor(
                                                    lesson.currentTime / 60
                                                )}
                                            </div>
                                            <div className='col col-1'>
                                                <Link
                                                    to={'#'}
                                                    className='btn btn-primary btn--small'>
                                                    Manage
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                });
                            })}
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(withRouter(UserProgress));
