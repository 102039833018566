import { Button, Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { jsPDF } from 'jspdf';

class DownloadButton extends Component {
    state = {
        preview: '',
    };

    handleDownload = () => {
        const doc = new jsPDF(),
            { attempt } = this.props,
            text = doc.splitTextToSize('RealEstateU'),
            { w } = doc.getTextDimensions(text);

        doc.setFontSize(9);
        doc.setFont('helvetica', 'bold');
        doc.text('ATTEMPT ID:', 17, 20);
        doc.setFont('helvetica', 'normal');
        doc.text(attempt._id.toUpperCase(), 38, 20);
        doc.setFont('helvetica', 'bold');

        doc.setFontSize(12);
        doc.text(text, 200 - w, 20);

        let yAxis = 30;

        doc.setFontSize(11);

        for (const questionIndex in attempt.questions) {
            const question = attempt.questions[questionIndex],
                text = doc.splitTextToSize(
                    question.title +
                        (attempt.answers[questionIndex] == null
                            ? ' - You have not answered this question'
                            : ''),
                    170
                ),
                { h } = doc.getTextDimensions(text);

            doc.setFont('helvetica', 'bold');
            doc.text(text, 17, yAxis);
            yAxis += h + 3;

            for (const optionIndex in question.options) {
                let text = doc.splitTextToSize(
                    question.options[optionIndex],
                    170
                );
                const { h } = doc.getTextDimensions(text);
                doc.setFont('helvetica', 'normal');
                doc.text(
                    '\u2022 ' +
                        text +
                        (attempt.answers[questionIndex] == optionIndex
                            ? ' - Your answer'
                            : '') +
                        (question.correctOptionIdx == optionIndex
                            ? ' - Correct answer'
                            : ''),
                    24,
                    yAxis
                );
                yAxis += h + 2;
            }

            yAxis += 6;

            if (yAxis > 240) {
                yAxis = 20;
                doc.addPage();
            }
        }

        doc.save(`attempt-${attempt._id}.pdf`);
    };

    render() {
        return (
            <Button className='bp mr-2' onClick={this.handleDownload}>
                Download
            </Button>
        );
    }
}

export default DownloadButton;
