import React, { Component } from 'react';
import './EndOfChapterQuiz.scss';
import apiCall from '../../../../helpers/apiCall';
import { Button } from 'react-bootstrap';
import { QuestionsModal } from '../../QuestionsModal';
import humanizeDuration from 'humanize-duration';
import { Alert } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { LastAttempts } from './LastAttempts';

class EndOfChapterQuiz extends Component {
    state = {
        lastAttempts: [],
        showLastAttempts: false,
        current: {
            pausedAt: [],
            startedAt: [],
        },
        showQuiz: false,
        message: null,
        modalError: '',
        attemptToShow: null,
    };

    async componentDidMount() {
        const { success, response } = await apiCall(
            'GET',
            `/users/chapters/${this.props.match.params.chapterId}/quiz`
        );

        if (success) {
            this.setState({
                ...response,
                message: response.lastAttempts[0]
                    ? {
                          type:
                              response.current?.pausedAt?.length &&
                              response.current?.pausedAt?.length !== 0 &&
                              response.current?.startedAt?.length ===
                                  response.current?.pausedAt?.length
                                  ? 'info'
                                  : response.lastAttempts[0].status ===
                                    'SUCCESS'
                                  ? 'success'
                                  : 'warning',
                          text:
                              response.current?.pausedAt?.length &&
                              response.current?.pausedAt?.length !== 0 &&
                              response.current?.startedAt?.length ===
                                  response.current?.pausedAt?.length
                                  ? `You have paused this quiz with ${humanizeDuration(
                                        response.current.timeLimit * 60000 -
                                            response.current.pausedAt.reduce(
                                                (totalTimeSpent, val, i) =>
                                                    (totalTimeSpent +=
                                                        new Date(
                                                            val
                                                        ).getTime() -
                                                        new Date(
                                                            response.current.startedAt[
                                                                i
                                                            ]
                                                        ).getTime()),
                                                0
                                            ),
                                        { round: true }
                                    )} left`
                                  : response.lastAttempts[0].status ===
                                    'SUCCESS'
                                  ? 'You have passed this quiz'
                                  : 'You have failed this quiz',
                      }
                    : null,
                showQuiz:
                    (!!response.current?.startedAt?.length &&
                        !response.current?.pausedAt?.length) ||
                    (!!response.current?.startedAt?.length &&
                        response.current?.pausedAt?.length <
                            response.current?.startedAt?.length),
            });
        }

        window.socket.on('quiz started', (startedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    startedAt,
                },
                showQuiz: true,
                modalError: '',
            });

            this.setLeaveWarning();
        });

        window.socket.on('quiz paused', (pausedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    pausedAt,
                },
                message: {
                    type: 'info',
                    text: `You have paused this quiz with ${humanizeDuration(
                        this.state.current.timeLimit * 60000 -
                            pausedAt.reduce(
                                (totalTimeSpent, val, i) =>
                                    (totalTimeSpent +=
                                        new Date(val).getTime() -
                                        new Date(
                                            this.state.current.startedAt[i]
                                        ).getTime()),
                                0
                            ),
                        { round: true }
                    )} left`,
                },
                showQuiz: false,
            });

            this.unsetLeaveWarning();
        });

        window.socket.on('quiz resumed', (startedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    startedAt,
                },
                modalError: '',
                showQuiz: true,
            });

            this.setLeaveWarning();
        });

        window.socket.on('quiz canceled', (response) => {
            this.setState({
                ...response,
                message: {
                    type: 'warning',
                    text: 'You have failed this quiz',
                },
                showQuiz: true,
            });

            this.handleAttempt();
        });

        window.socket.on('quiz submited', (response) => {
            if (response.success) {
                this.setState({
                    ...response.data,
                    message: response.data.lastAttempts[0]
                        ? {
                              type:
                                  response.data.lastAttempts[0].status ===
                                  'SUCCESS'
                                      ? 'success'
                                      : 'warning',
                              text:
                                  response.data.lastAttempts[0].status ===
                                  'SUCCESS'
                                      ? 'You have passed this quiz'
                                      : 'You have failed this quiz',
                          }
                        : null,
                    showQuiz: false,
                });

                this.unsetLeaveWarning();
            } else {
                this.setState({
                    modalError: response.message,
                });
            }
        });
    }

    leaveWarningCallback(event) {
        const e = event || window.event;

        e.returnValue = '';

        e.preventDefault();
    }

    setLeaveWarning = () => {
        window.addEventListener('beforeunload', this.leaveWarningCallback);
    };

    unsetLeaveWarning = () => {
        window.removeEventListener('beforeunload', this.leaveWarningCallback);
    };

    checkAnswer = async (questionIndex, option) => {
        const { success, response } = await apiCall(
            'GET',
            `/users/chapters/quiz/${this.state.current._id}/answer/${questionIndex}/${option}`
        );

        if (success) {
            let answers = this.state.current.answers;

            answers[questionIndex] = option;

            this.setState({
                marks: this.state.marks + response.marks,
                quiz: {
                    ...this.state.current,
                    answers,
                },
            });
        }
    };

    handleCancel = async () => {
        window.socket.emit('cancel quiz', this.state.current?._id);
    };

    handlePause = async () => {
        window.socket.emit('pause quiz', this.state.current?._id);
    };

    handleResume = async () => {
        window.socket.emit('resume quiz', this.state.current?._id);
    };

    handleAttempt = async () => {
        window.socket.emit('start quiz', this.state.current?._id);
    };

    handleSubmit = async () => {
        window.socket.emit('submit quiz', this.state.current?._id);
    };

    hideLastAttempt = () => {
        this.setState({
            attemptToShow: null,
        });
    };

    setAttemptToShow = (attemptToShow) => {
        this.setState({
            attemptToShow,
        });
    };

    render() {
        const { current, lastAttempts } = this.state;

        return (
            <div className='quiz-info'>
                <div>
                    <h3>
                        <b>{current?.title ?? lastAttempts[0]?.title}</b>
                    </h3>
                    {this.state.message && (
                        <Alert variant={this.state.message.type}>
                            {this.state.message.text}
                        </Alert>
                    )}
                    {current?.questions?.length ??
                        lastAttempts[0]?.questions?.length}{' '}
                    questions
                    <br />
                    Passing percentage:{' '}
                    {current?.passPct ?? lastAttempts[0]?.passPct}
                    %
                    <br />
                    Time limit:{' '}
                    {humanizeDuration(
                        (current?.timeLimit ?? lastAttempts[0]?.timeLimit) *
                            60000
                    )}
                    <br />
                    <br />
                    {this.state.current &&
                        (!lastAttempts[0] ||
                            lastAttempts[0]?.allowReattempt !== 'never') && (
                            <Button
                                className='bp mr-3'
                                onClick={
                                    current?.pausedAt?.length &&
                                    current?.pausedAt?.length !== 0 &&
                                    current?.startedAt?.length ===
                                        current?.pausedAt?.length
                                        ? this.handleResume
                                        : this.handleAttempt
                                }>
                                {current?.pausedAt?.length &&
                                current?.pausedAt?.length !== 0 &&
                                current?.startedAt?.length ===
                                    current?.pausedAt?.length
                                    ? 'Resume'
                                    : current && lastAttempts[0]
                                    ? 'Reattempt'
                                    : 'Attempt'}
                                &nbsp;now
                            </Button>
                        )}
                    {!!current?.pausedAt?.length &&
                        current?.pausedAt?.length !== 0 &&
                        current?.startedAt?.length ===
                            current?.pausedAt?.length && (
                            <Button className='bd' onClick={this.handleCancel}>
                                Start new quiz
                            </Button>
                        )}
                </div>
                {!!lastAttempts.length && (
                    <LastAttempts
                        items={lastAttempts}
                        onItemClick={this.setAttemptToShow}
                    />
                )}
                {current &&
                    current?.startedAt?.length > current?.pausedAt?.length && (
                        <QuestionsModal
                            show={this.state.showQuiz}
                            title={current?.title}
                            questions={current?.questions}
                            answers={current?.answers}
                            error={this.state.modalError}
                            timeLimit={
                                current.pausedAt.slice(-1).pop()
                                    ? new Date(
                                          current.startedAt.slice(-1).pop()
                                      ).getTime() +
                                      (current.timeLimit * 60000 -
                                          current.pausedAt.reduce(
                                              (totalTimeSpent, val, i) =>
                                                  (totalTimeSpent +=
                                                      new Date(val).getTime() -
                                                      new Date(
                                                          current.startedAt[i]
                                                      ).getTime()),
                                              0
                                          ))
                                    : new Date(
                                          current.startedAt.slice(-1).pop()
                                      ).getTime() +
                                      current?.timeLimit * 60000
                            }
                            allowSkip={current?.allowSkip}
                            onAnswer={this.checkAnswer}
                            onSubmit={this.handleSubmit}
                            onPause={this.handlePause}
                        />
                    )}
                {this.state.attemptToShow && (
                    <QuestionsModal
                        show={!!this.state.attemptToShow}
                        onHide={this.hideLastAttempt}
                        questions={this.state.attemptToShow?.questions}
                        title={this.state.attemptToShow?.title}
                        answers={this.state.attemptToShow?.answers}
                        attempt={this.state.attemptToShow}
                        readOnly={true}
                    />
                )}
            </div>
        );
    }
}

export default withRouter(EndOfChapterQuiz);
