import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import apiCall from '../../../helpers/apiCall';
import '../../../components/ApiTable/ApiTable.scss';
import {
    Card,
    Col,
    Form,
    FormGroup,
    Row,
    Button,
    Accordion,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { Spinner } from '../../../components/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModal } from '../../../components/ConfirmationModal';

class UserPackages extends Component {
    state = {
        userPackages: [],
        availablePackages: [],
        selectedPackageId: '',
        loading: true,
        modalShow: false,
        modalAction: null,
    };

    loadPackages = async () => {
        this.setState({
            loading: true,
        });
        const {
            success: successPackages,
            response: responsePackages,
        } = await apiCall('GET', `/packages`);

        const {
            success: successUserPckg,
            response: responseUserPckg,
        } = await apiCall(
            'GET',
            `/users/${this.props.user._id}/packages?perPage=all`
        );

        let userPackages = [];

        if (successUserPckg && responseUserPckg.docs) {
            userPackages = responseUserPckg.docs.map(async (userPackage) => {
                const { success, response } = await apiCall(
                    'GET',
                    `/users/${this.props.user._id}/packages/${userPackage._id}/courses`
                );

                if (success && response.courses && response.courses.length) {
                    userPackage.courses = response.courses;
                }
                return userPackage;
            });
            userPackages = await Promise.all(userPackages);
        }

        this.setState({
            availablePackages: responsePackages.docs,
            userPackages,
            loading: false,
        });
    };

    componentDidMount = async () => {
        this.loadPackages();
    };

    selectPackage(event) {
        this.setState({
            selectedPackageId: event.target.value,
        });
    }

    addPackage = async (event) => {
        event.preventDefault();

        this.setState({
            loading: true,
        });
        const { success, response } = await apiCall(
            'POST',
            `/users/${this.props.user._id}/packages/add/${this.state.selectedPackageId}`
        );

        if (success) {
            this.loadPackages();
        }
    };

    removeUserPackage = async (id) => {
        this.setState({
            loading: true,
        });

        const { success, response } = await apiCall(
            'DELETE',
            `/users/${this.props.user._id}/packages/${id}`
        );

        if (success) {
            this.loadPackages();
            this.props.setGlobalAlert({
                type: 'success',
                message: `Package has been deleted`,
            });
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message: `Package has not been deleted`,
            });
            this.setState({
                loading: false,
            });
        }

        this.setState({
            modalShow: false,
        });
    };

    render() {
        if (this.state.loading) {
            return <Spinner />;
        } else {
            return (
                <div>
                    <ConfirmationModal
                        show={this.state.modalShow}
                        hideModal={() => {
                            this.setState({
                                modalShow: false,
                            });
                        }}
                        confirmAction={this.state.modalAction}
                        titleText={'Are you sure?'}
                        bodyText={[
                            'You are about to ',
                            <strong key='modal-type'>remove</strong>,
                            ' this Package Subscription.',
                        ]}
                    />

                    <Form>
                        <Row className='pt-4'>
                            <Col>
                                <FormGroup>
                                    <Form.Label>
                                        Package Subscriptions
                                    </Form.Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        {this.state.userPackages.map((item, i) => {
                            return (
                                <Row className='pt-2' key={i}>
                                    <Col>
                                        <Accordion defaultActiveKey='1'>
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey='0'>
                                                    <Row>
                                                        <Col xs={8}>
                                                            {item.package.title}
                                                        </Col>
                                                        <Col xs={4}>
                                                            <OverlayTrigger
                                                                key={`tooltip-remove-${i}`}
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip
                                                                        id={`tooltip-remove-${i}`}>
                                                                        Remove
                                                                    </Tooltip>
                                                                }>
                                                                <Button
                                                                    variant='link'
                                                                    type='button'
                                                                    className='float-right mx-1'
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        this.setState(
                                                                            {
                                                                                modalShow: true,
                                                                                modalAction: () => {
                                                                                    this.removeUserPackage(
                                                                                        item._id
                                                                                    );
                                                                                },
                                                                            }
                                                                        );
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faTimes
                                                                        }
                                                                    />
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <OverlayTrigger
                                                                key={`tooltip-view-${i}`}
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip
                                                                        id={`tooltip-view-${i}`}>
                                                                        View
                                                                    </Tooltip>
                                                                }>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant='link'
                                                                    className='float-right mx-1'
                                                                    eventKey='0'>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faEye
                                                                        }
                                                                    />
                                                                </Accordion.Toggle>
                                                            </OverlayTrigger>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey='0'>
                                                    <Card.Body>
                                                        <div className='table-wrapper'>
                                                            <div className='table'>
                                                                <header>
                                                                    <div
                                                                        style={{
                                                                            minWidth:
                                                                                'auto',
                                                                            maxWidth:
                                                                                'auto',
                                                                        }}
                                                                        className='col'>
                                                                        Course
                                                                        name
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            minWidth:
                                                                                'auto',
                                                                            maxWidth:
                                                                                'auto',
                                                                        }}
                                                                        className='col'>
                                                                        Enrolled
                                                                        on
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            minWidth:
                                                                                'auto',
                                                                            maxWidth:
                                                                                'auto',
                                                                        }}
                                                                        className='col'>
                                                                        Progress
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            minWidth:
                                                                                'auto',
                                                                            maxWidth:
                                                                                'auto',
                                                                        }}
                                                                        className='col'>
                                                                        Actions
                                                                    </div>
                                                                </header>
                                                                <main>
                                                                    {item.courses.map(
                                                                        (
                                                                            course,
                                                                            j
                                                                        ) => {
                                                                            return (
                                                                                <div
                                                                                    key={
                                                                                        j
                                                                                    }
                                                                                    className='row'>
                                                                                    <div
                                                                                        style={{
                                                                                            minWidth:
                                                                                                'auto',
                                                                                            maxWidth:
                                                                                                'auto',
                                                                                        }}
                                                                                        className='col'>
                                                                                        {
                                                                                            course.title
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            minWidth:
                                                                                                'auto',
                                                                                            maxWidth:
                                                                                                'auto',
                                                                                        }}
                                                                                        className='col'>
                                                                                        {course.enrollmentUpdatedAt
                                                                                            ? new Date(
                                                                                                  course.enrollmentUpdatedAt
                                                                                              ).toLocaleDateString()
                                                                                            : '-'}
                                                                                    </div>
                                                                                    <div
                                                                                        style={{
                                                                                            minWidth:
                                                                                                'auto',
                                                                                            maxWidth:
                                                                                                'auto',
                                                                                        }}
                                                                                        className='col'>
                                                                                        {
                                                                                            course.percentageProgress
                                                                                        }{' '}
                                                                                        %
                                                                                    </div>
                                                                                    <div className='col'>
                                                                                        <Link
                                                                                            to={
                                                                                                '#'
                                                                                            }
                                                                                            className='btn btn-primary btn--small'>
                                                                                            Show
                                                                                            sessions
                                                                                        </Link>
                                                                                        &nbsp;
                                                                                        <Link
                                                                                            to={`/admin/users/${this.props.match.params.id}/course/${course._id}/progress`}
                                                                                            className='btn btn-primary btn--small'>
                                                                                            Show
                                                                                            progress
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                </main>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </Col>
                                </Row>
                            );
                        })}

                        <FormGroup className='pt-5'>
                            <p>Add new Package</p>
                        </FormGroup>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Control
                                        as='select'
                                        value={this.state.selectedPackageId}
                                        custom
                                        onChange={(event) => {
                                            this.selectPackage(event);
                                        }}>
                                        <option disabled value=''>
                                            Select...
                                        </option>
                                        {this.state.availablePackages.map(
                                            (option, i) => {
                                                return (
                                                    <option
                                                        key={i}
                                                        value={option._id}>
                                                        {option.title}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button
                                    type='button'
                                    variant='dark'
                                    onClick={this.addPackage}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        }
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(UserPackages));
