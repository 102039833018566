import React, { Component } from 'react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faFrown, faSmile } from '@fortawesome/free-regular-svg-icons';
import './LastAttempts.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class LastAttempts extends Component {
    render() {
        return (
            <div className='last-attempts'>
                <h3>Your past attempts</h3>
                <div className='last-attempts__items'>
                    {this.props.items.map((item) => (
                        <OverlayTrigger
                            key={item._id}
                            overlay={
                                <Tooltip id={`tooltip-attempt-${item._id}`}>
                                    {item.status === 'FAIL'
                                        ? 'Failed'
                                        : 'Passed'}
                                </Tooltip>
                            }>
                            <div
                                onClick={() => {
                                    this.props.onItemClick(item);
                                }}
                                className={`last-attempts__item${
                                    item.status === 'FAIL'
                                        ? ' last-attempts__item--fail'
                                        : ' last-attempts__item--success'
                                }`}>
                                <i>
                                    <Fa
                                        icon={
                                            item.status === 'FAIL'
                                                ? faFrown
                                                : faSmile
                                        }
                                    />
                                </i>
                                <b>
                                    Attempted on{' '}
                                    {new Date(
                                        item.startedAt[0]
                                    ).toLocaleString()}
                                </b>
                            </div>
                        </OverlayTrigger>
                    ))}
                </div>
            </div>
        );
    }
}

export { LastAttempts };
