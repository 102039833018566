import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
    setUserInactive,
    setLastSeen,
} from '../../../store/actions/userActions';
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInactive: false,
            playing: false,
        };
    }

    componentDidUpdate() {
        if (this.props.userInactive.state != this.state.userInactive) {
            if (this.props.userInactive.state) {
                this.setState({ userInactive: this.props.userInactive.state });
                if (this.props.currentCourse.state.timerPause) {
                    this.setState({ playing: false });
                }
            } else {
                this.setState({ userInactive: this.props.userInactive.state });
            }
        }
    }

    handleUserPause = () => {
        // have a request here as well when endpoints ready
        if (this.props.currentCourse.state.timerPause) {
            this.setState({ playing: false });
            this.props.setLastSeen();
            this.props.setUserInactive(true);
        }
    };

    handleUserPlay = () => {
        // have a request here as well when endpoints ready
        if (this.props.currentCourse.state.timerPause) {
            this.setState({ playing: true });
            this.props.setUserInactive(false);
        }
    };

    render() {
        const { sourceVideo } = this.props;
        return (
            <ReactPlayer
                url={sourceVideo}
                width='100%'
                height='100%'
                playing={this.state.playing}
                onPause={() => {
                    this.handleUserPause();
                }}
                onPlay={() => {
                    this.handleUserPlay();
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { userInactive, currentCourse } = state;
    return {
        userInactive,
        currentCourse,
    };
};

const mapDispatchToProps = {
    setUserInactive,
    setLastSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
