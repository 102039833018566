import React, { Component } from 'react';
import LessonSlider from '../../../components/User/LessonSlider/LessonSlider';
import apiCall from '../../../helpers/apiCall';
import { connect } from 'react-redux';
import {
    setAllChapters,
    setAllLessons,
    setCurrentLessonsCardIds,
    setCurrentLessonLayout,
    setCurrentLesson,
    setCurrentCourse,
    setCurrentChapter,
} from '../../../store/actions/courseActions';

import { setShowCourseMenu } from '../../../store/actions/courseMenuActions';

class UserLesson extends Component {
    _isMounted = false;

    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.props.setShowCourseMenu(true);
            this.storePageData();
        }
    }

    async componentDidUpdate(prevProps) {
        const { chapterId } = this.props.match.params;

        if (
            prevProps.match.params.chapterId !=
            this.props.match.params.chapterId
        ) {
            await this.setCurrentChapter();
            this.props.setAllLessons(chapterId);
        }

        if (
            prevProps.match.params.lessonId != this.props.match.params.lessonId
        ) {
            await this.setCurrentLesson();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async setCurrentLesson() {
        const { lessonId } = this.props.match.params;

        const {
            success: foundCurrentLesson,
            response: currentLesson,
        } = await apiCall('GET', `/users/lessons/${lessonId}`);

        if (foundCurrentLesson) {
            // get current lesson by lessonId in route.
            this.props.setCurrentLesson(currentLesson.lesson[0]);
        }
        if (currentLesson.lesson[0].lessonLayout == 'PAGE') {
            this.props.setCurrentLessonLayout('PAGE');
        } else if (currentLesson.lesson[0].lessonLayout == 'CARD') {
            this.props.setCurrentLessonLayout('CARD');
        }
        const {
            // We then need card ids for this lesson
            success: foundCards,
            response: lessonCardIds,
        } = await apiCall(
            'GET',
            `/courses/lessons/${currentLesson.lesson[0].courseLessonId}/cards`
        );
        if (foundCards) {
            this.props.setCurrentLessonsCardIds(lessonCardIds);
        }
    }

    async setCurrentChapter() {
        const { chapterId } = this.props.match.params;
        const {
            success: foundCurrentChapter,
            response: currentChapter,
        } = await apiCall('GET', `/users/chapters/${chapterId}`);
        if (foundCurrentChapter) {
            this.props.setCurrentChapter(currentChapter);
        }
    }

    async setCurrentCourse() {
        const { courseId } = this.props.match.params;
        const {
            success: foundCurrentCourse,
            response: currentCourse,
        } = await apiCall('GET', `/users/courses/${courseId}`);
        if (foundCurrentCourse) {
            this.props.setCurrentCourse(currentCourse);
        }
    }

    async storePageData() {
        const { courseId, chapterId } = this.props.match.params;

        Promise.allSettled([
            await this.setCurrentCourse(),
            await this.setCurrentChapter(),
            await this.props.setAllChapters(courseId),
            await this.props.setAllLessons(chapterId),
            await this.setCurrentLesson(),
        ]);
    }

    render() {
        if (Object.keys(this.props.currentCourse).length > 0) {
            return (
                <div>
                    <LessonSlider />
                </div>
            );
        } else {
            return <div />;
        }
    }
}

const mapStateToProps = (state) => {
    const {
        currentLesson,
        currentLessonLayout,
        currentCourse,
        currentChapter,
        allChapters,
    } = state;
    return {
        currentLesson,
        currentLessonLayout,
        currentCourse,
        currentChapter,
        allChapters,
    };
};

const mapDispatchToProps = {
    setAllChapters,
    setAllLessons,
    setCurrentLessonsCardIds,
    setCurrentLessonLayout,
    setShowCourseMenu,
    setCurrentLesson,
    setCurrentCourse,
    setCurrentChapter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLesson);
