import './User.scss';
import React, { Component } from 'react';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Col, Nav, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import UserProfile from './UserProfile';
import UserPackages from './UserPackages';
import apiCall from '../../../helpers/apiCall';
import UserActions from './UserActions';

class User extends Component {
    state = {
        user: null,
        modal: null,
    };

    async componentDidMount() {
        const { success, response, message } = await apiCall(
            'GET',
            '/users/' + this.props.match.params.id
        );

        if (success) {
            this.setState({
                user: response,
            });

            this.props.pushBreadcrumbLink({
                text: `${response.firstName} ${response.lastName}`,
                path: '/admin/users/' + this.props.match.params.id,
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    }

    componentWillUnmount() {
        this.props.removeBreadcrumbLink({
            text: `${this.state.user.firstName} ${this.state.user.lastName}`,
            path: '/admin/users/' + this.props.match.params.id,
        });
    }

    setUserModal = (modal) => {
        this.setState({
            modal,
        });
    };

    render() {
        if (this.state.user) {
            const { match } = this.props;
            return (
                <div id='user-page'>
                    <div id='user-id'>
                        <Row>
                            <Col>
                                <h3>
                                    {this.state.user.firstName}
                                    &nbsp;
                                    {this.state.user.lastName}
                                </h3>
                            </Col>
                            <Col
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <UserActions
                                    verified={!!this.state.user.verified}
                                    suspended={!!this.state.user.suspended}
                                    userId={this.state.user._id}
                                    setUserModal={this.setUserModal}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>
                                    <b>Account ID: </b>
                                    {this.state.user._id
                                        .toLocaleUpperCase()
                                        .substr(0, 16)
                                        .replace(
                                            /([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})([A-Z0-9]{4})/,
                                            '$1-$2-$3-$4'
                                        )}
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span>
                                    <b>Student ID: </b>
                                    {this.state.user._id
                                        .toLocaleUpperCase()
                                        .substr(-8)
                                        .replace(
                                            /([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})([A-Z0-9]{2})/,
                                            '$1-$2-$3-$4'
                                        )}
                                </span>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <Nav variant='pills' defaultActiveKey='profile' as={'nav'}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey='profile'
                                as={Link}
                                to={
                                    '/admin/users/' +
                                    match.params.id +
                                    '/profile'
                                }>
                                Profile
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                eventKey='packages'
                                as={Link}
                                to={
                                    '/admin/users/' +
                                    match.params.id +
                                    '/packages'
                                }>
                                Packages
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Switch>
                        <Route path='/admin/users/:id/profile'>
                            <UserProfile user={this.state.user} />
                        </Route>
                        <Route path='/admin/users/:id/packages'>
                            <UserPackages user={this.state.user} />
                        </Route>
                        <Redirect
                            exact
                            from='/admin/users/:id'
                            to='/admin/users/:id/profile'
                        />
                    </Switch>
                    {this.state.modal}
                </div>
            );
        }
        return <div />;
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
})(withRouter(User));
