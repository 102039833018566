import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import apiCall from '../../../helpers/apiCall';
import { FileUpload } from '../../../components/FileUpload';
import { ContentItems } from '../../../components/FormItems';
import { Spinner } from '../../../components/Spinner';
import { Button, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';

class CourseForm extends Component {
    _isMounted = false;

    initialState = {
        loading: true,
        redirect: null,
        title: '',
        description: '',
        imageUrl: '',
        imageFile: '',
        inactiveTime: '',
        timerPause: '',
        timeFrame: '',
        doSubmit: false,
        submitRegister: {},
        rerenderId: 0,
        isDirty: false,
    };

    constructor(props) {
        super(props);

        this.docId = this.props.match.params.id;

        this.state = { ...this.initialState };
    }

    updateRegister = (children, action, parentDocId) => {
        if (!Array.isArray(children)) {
            children = [children];
        }

        let updatedRegister = { ...this.state.submitRegister };

        const deleteChildren = (child) => {
            Object.keys(updatedRegister).map((docId) => {
                if (updatedRegister[docId].parent === child._id) {
                    delete updatedRegister[docId];
                }
            });
        };

        let shouldRedirect = this.state.doSubmit;
        children.map((child) => {
            if (action === 'add') {
                updatedRegister[child._id] = {
                    submitted: false,
                    error: false,
                    parent: parentDocId,
                };
            } else if (action === 'delete') {
                delete updatedRegister[child._id];
                deleteChildren(child);
                shouldRedirect = false;
            } else if (action === 'delete_children') {
                deleteChildren(child);
                shouldRedirect = false;
            } else if (action === 'submitted' && updatedRegister[child._id]) {
                updatedRegister[child._id].submitted = true;
            } else if (action === 'error' && updatedRegister[child._id]) {
                updatedRegister[child._id].error = true;
            }
        });

        this.setState({
            submitRegister: updatedRegister,
        });

        const anyError = this.anyErrorInChildren(updatedRegister);
        shouldRedirect = !anyError;

        const allSubmitted = this.areAllChildrenSubmitted(updatedRegister);

        if (shouldRedirect && allSubmitted) {
            this.doRedirect(this.state.submittedDocId);
        } else if (anyError && this.state.doSubmit) {
            this.handleSubmitFailed();
        }
    };

    areAllChildrenSubmitted = (updatedRegister) => {
        const register = updatedRegister
            ? updatedRegister
            : this.state.submitRegister;
        return Object.keys(register).every((key) => {
            return register[key].submitted;
        });
    };

    anyErrorInChildren = (updatedRegister) => {
        const register = updatedRegister
            ? updatedRegister
            : this.state.submitRegister;
        return Object.keys(register).some((key) => {
            return register[key].error;
        });
    };

    doRedirect = (courseId) => {
        this.toggleSpinner(false);

        this.props.setGlobalAlert({
            type: 'success',
            message: `Course has been ${
                this.docId !== 'new' ? 'updated' : 'created'
            }`,
        });

        if (this.docId === 'new') {
            this.setState({
                redirect: `/admin/courses/${courseId}`,
            });
        } else {
            this.setState({
                doSubmit: false,
                rerenderId: this.state.rerenderId + 1,
                submitRegister: {},
            });
            this.removeBreadcrumbs();
            this.createBreadcrumbs();
        }
    };

    getPayload = () => {
        return {
            title: this.state.title,
            description: this.state.description,
            image: this.state.imageUrl,
            timerPause: this.state.timerPause,
            inactiveTime: this.state.inactiveTime * 60,
            timeFrame: this.state.timeFrame,
        };
    };

    setIsDirty = (dirty) => {
        this.setState({
            isDirty: dirty,
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isDirty: true,
        });
    };

    handleImageChange = (type, imageUrl, imageFile) => {
        this.setState({
            imageUrl,
            imageFile,
            isDirty: true,
        });
    };

    handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setIsDirty(false);
        this.toggleSpinner(true);
        await this.submit();
    };

    handleSubmitFailed = () => {
        this.props.setGlobalAlert({
            type: 'error',
            message:
                'There was a problem with saving this Course. Please try again',
        });
        this.setState({
            doSubmit: false,
            rerenderId: this.state.rerenderId + 1,
        });
        this.toggleSpinner(false);
    };

    toggleSpinner = (show) => {
        const form = document.getElementById('courseForm');
        const spinner = document.getElementById('spinner');
        if (form && spinner) {
            if (show) {
                form.style.display = 'none';
                spinner.removeAttribute('hidden');
            } else {
                form.style.display = 'block';
                spinner.setAttribute('hidden', true);
            }
        }
    };

    submit = async () => {
        if (this.state.imageFile) {
            let imagePostData = new FormData();
            imagePostData.append('file', this.state.imageFile);
            const { success, response } = await apiCall(
                'POST',
                '/file',
                imagePostData
            );

            if (success && this._isMounted) {
                this.setState({
                    imageUrl: response.url,
                });
            }
        }

        if (!this._isMounted) {
            return;
        }

        let submitMethod = 'POST';
        let submitUrl = '/courses';

        if (this.docId !== 'new') {
            submitMethod = 'PUT';
            submitUrl = `/courses/${this.docId}`;
        }

        const { success, response } = await apiCall(
            submitMethod,
            submitUrl,
            this.getPayload()
        );

        if (this._isMounted) {
            if (success && response) {
                if (this.areAllChildrenSubmitted()) {
                    this.doRedirect(response._id);
                } else {
                    this.setState({
                        doSubmit: true,
                        submittedDocId: response._id,
                    });
                }
            } else {
                this.handleSubmitFailed();
            }
        }
    };

    removeBreadcrumbs = () => {
        this.props.removeBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        if (this.props.match.params.id !== 'new') {
            this.props.removeBreadcrumbLink({
                text: `Course: ${this.state.title}`,
                path: `/admin/courses/${this.props.match.params.id}`,
            });
        }
    };

    createBreadcrumbs = () => {
        this.props.pushBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        if (this.props.match.params.id !== 'new') {
            this.props.pushBreadcrumbLink({
                text: `Course: ${this.state.title}`,
                path: `/admin/courses/${this.props.match.params.id}`,
            });
        }
    };

    componentDidMount = async () => {
        this._isMounted = true;
        if (this.docId !== 'new') {
            await this.loadData();
        }
        this.createBreadcrumbs();
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        this.removeBreadcrumbs();
    };

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.docId = this.props.match.params.id;
            await this.loadData();
            this.createBreadcrumbs();
        }
    }

    loadData = async () => {
        if (this.docId && this.docId !== 'new') {
            this.setState({
                loading: true,
            });
            const { success, response } = await apiCall(
                'GET',
                `/courses/${this.docId}`
            );
            if (success && this._isMounted) {
                this.setState({
                    ...response,
                    imageUrl: response.image,
                    timeFrame: response.timeFrame,
                    inactiveTime: response.inactiveTime / 60,
                    loading: false,
                    redirect: null,
                    rerenderId: this.state.rerenderId + 1,
                });
            }
        } else {
            this.setState({
                ...this.initialState,
                loading: false,
            });
        }
    };

    onKeyPress = (event) => {
        if (event.charCode === 13 && event.target.nodeName !== 'TEXTAREA') {
            event.preventDefault();
            const allowedTags = ['TEXTAREA', 'INPUT', 'SELECT'];
            const form = document.getElementById('form');
            const allowedElems = Array.from(form.elements).filter((elem) => {
                return allowedTags.indexOf(elem.tagName) >= 0;
            });

            const index = allowedElems.indexOf(event.target);
            allowedElems[(index + 1) % allowedElems.length].focus();
        }
    };

    render() {
        let mainContent;

        if (this.state.redirect) {
            mainContent = <Redirect to={this.state.redirect} />;
        } else if (this.state.loading && this.docId !== 'new') {
            mainContent = <Spinner />;
        } else {
            mainContent = (
                <div>
                    <div id='spinner' hidden>
                        <Spinner />
                    </div>
                    <Form
                        onSubmit={this.handleSubmit}
                        id='courseForm'
                        onKeyPress={this.onKeyPress}>
                        <Row className='pt-5'>
                            <Col xs={12} lg={4}>
                                <FormGroup>
                                    <Form.Label htmlFor='title'>
                                        Course Name
                                    </Form.Label>
                                    <Form.Control
                                        type='text'
                                        required
                                        minLength='3'
                                        maxLength='512'
                                        id='title'
                                        name='title'
                                        value={this.state.title}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Form.Label htmlFor='description'>
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        type='text'
                                        required
                                        rows='5'
                                        id='description'
                                        name='description'
                                        value={this.state.description}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormGroup>
                                    <Form.Label htmlFor='inactiveTime'>
                                        Inactive Time (minutes)
                                    </Form.Label>
                                    <Form.Control
                                        type='number'
                                        min='0'
                                        id='inactiveTime'
                                        name='inactiveTime'
                                        value={this.state.inactiveTime}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Form.Label htmlFor='timerPause'>
                                        Timer pause on media controls
                                    </Form.Label>
                                    <Form.Control
                                        as='select'
                                        required
                                        id='timerPause'
                                        name='timerPause'
                                        value={this.state.timerPause}
                                        onChange={this.handleChange}>
                                        <option disabled value=''></option>
                                        <option value='true'>Yes</option>
                                        <option value='false'>No</option>
                                    </Form.Control>
                                </FormGroup>
                                <FormGroup>
                                    <Form.Label htmlFor='timeFrame'>
                                        Time Frame (days)
                                    </Form.Label>
                                    <Form.Control
                                        id='timeFrame'
                                        name='timeFrame'
                                        type='number'
                                        min='0'
                                        step='1'
                                        value={this.state.timeFrame}
                                        onChange={this.handleChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormGroup>
                                    <FileUpload
                                        id='image'
                                        name='image'
                                        url={this.state.imageUrl}
                                        handleFileChange={
                                            this.handleImageChange
                                        }
                                        type='image'
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className='pt-4'>
                            <Col>
                                <ContentItems
                                    key={this.state.rerenderId}
                                    type='chapter'
                                    source='courses'
                                    parentDocId={
                                        this.state.submittedDocId || this.docId
                                    }
                                    doSubmit={
                                        this.state.submittedDocId &&
                                        this.state.doSubmit
                                    }
                                    setIsDirty={this.setIsDirty}
                                    updateRegister={this.updateRegister}
                                />
                            </Col>
                        </Row>
                        <Row className='pt-5'>
                            <Col className='pr-4'>
                                <Button
                                    variant='primary'
                                    type='submit'
                                    className='w-100'>
                                    Save
                                </Button>
                            </Col>
                            <Col className='pl-4'>
                                <Button
                                    variant='outline-primary'
                                    onClick={this.loadData}
                                    className='w-100'>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        }
        return (
            <div>
                <RouteLeavingGuard
                    when={this.state.isDirty}
                    navigate={(path) => this.props.history.push(path)}
                    shouldBlockNavigation={() => {
                        return this.state.isDirty;
                    }}
                />
                <main>{mainContent}</main>
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(CourseForm));
