import React, { Component } from 'react';
import { RiArrowRightSLine, RiContactsBookLine } from 'react-icons/ri';
import './LessonSlider.scss';
import { connect } from 'react-redux';
import CardSelector from './CardSelector';
import { IconContext } from 'react-icons';
import apiCall from '../../../helpers/apiCall';
import { withRouter } from 'react-router-dom';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { RequiredTimeShield, InactivityModal } from '../LessonModals';
import UserTracker from '../UserTracker';
import {
    setCurrentLesson,
    setCurrentChapter,
    setUnlockedLessons,
    setCompletedLessons,
    setNewCoursePopUp,
    setUpdateCoursePercentage,
} from '../../../store/actions/courseActions';
import { setUpdateCourseMenuLessons } from '../../../store/actions/courseMenuActions';

class LessonSlider extends Component {
    _isMounted = false;
    constructor() {
        super();
        this.state = {
            time: {},
            seconds: 0,
            nextLesson: {},
            requiredTimeShield: false,
            showInactivityModal: false,
        }; // Pass only seconds here. We automatically format to hours:minutes:seconds
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            h: hours < 10 ? '0' + hours : hours,
            m: minutes < 10 ? '0' + minutes : minutes,
            s: seconds < 10 ? '0' + seconds : seconds,
        };
        return obj;
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (Object.keys(this.props.currentLesson).length > 0) {
                this.setNextLesson();
                const requiredTime =
                    this.props.currentLesson.state.currentTime <
                    this.props.currentLesson.state.requiredTime
                        ? this.props.currentLesson.state.requiredTime * 60 -
                          this.props.currentLesson.state.currentTime
                        : 0;
                if (requiredTime > 0) {
                    this.startTimer();
                    let timeLeftVar = this.secondsToTime(requiredTime);
                    this.setState({ time: timeLeftVar, seconds: requiredTime });

                    this.currentLessonTime = setInterval(async () => {
                        if (!this.props.userInactive.state) {
                            await apiCall(
                                'POST',
                                `/users/lessons/${this.props.currentLesson.state._id}/timer`
                            );
                        }
                    }, 5000);
                } else {
                    clearInterval(this.timer);
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async setNextLesson() {
        const nextLessonIndex = this.props.currentLesson.state.menuIndex + 1;
        const lastLessonIndex = this.props.allLessons.state.length;
        const nextChapterIndex = this.props.currentChapter.state.menuIndex;
        const lastChapterIndex = this.props.allChapters.state.length;
        if (nextLessonIndex < lastLessonIndex) {
            const { success, response } = await apiCall(
                'GET',
                `/users/lessons/${this.props.allLessons.state[nextLessonIndex]._id}`
            );
            if (success) {
                this.setState({
                    nextLesson: response.lesson[0],
                });
            }
        } else if (nextChapterIndex < lastChapterIndex) {
            const nextChapterId = this.props.allChapters.state[nextChapterIndex]
                ._id;
            this.props.setCurrentChapter(
                this.props.allChapters.state[nextChapterIndex]
            );
            const {
                response: lessons,
                success: foundAllChapterLessons,
            } = await apiCall(
                'GET',
                `/users/chapters/${nextChapterId}/lessons`
            );
            if (foundAllChapterLessons) {
                this.setState({
                    nextLesson: lessons.lessons[0],
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentLesson != this.props.currentLesson) {
            this.setNextLesson();
            if (this.props.currentLesson.state.requiredTime * 60 > 0) {
                clearInterval(this.currentLessonTime);
                this.resetTimer();
                this.currentLessonTime = setInterval(async () => {
                    if (!this.props.userInactive.state) {
                        await apiCall(
                            'POST',
                            `/users/lessons/${this.props.currentLesson.state._id}/timer`
                        );
                    }
                }, 5000);
            } else {
                clearInterval(this.timer);
            }
        }
    }

    async startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        } else if (
            this.props.currentLesson.state.requiredTime == 0 ||
            'completed' in this.props.currentLesson.state
        ) {
            await this.markLessonUnlockedAndComplete();
        }
    }

    resetTimer() {
        const requiredTime =
            this.props.currentLesson.state.requiredTime * 60 -
            this.props.currentLesson.state.currentTime;
        let timeLeftVar = this.secondsToTime(requiredTime);
        this.setState({ time: timeLeftVar, seconds: requiredTime });
        clearInterval(this.timer);
        this.timer = setInterval(this.countDown, 1000);
    }

    async countDown() {
        let seconds;
        if (!this.props.userInactive.state) {
            seconds = this.state.seconds - 1;
        } else {
            seconds = this.state.seconds;
            this.setState({ showInactivityModal: true });
        }

        // Check if we're at zero and mark lesson complete.
        if (seconds <= 0 || this.props.currentLesson.state.requiredTime == 0) {
            await this.markLessonUnlockedAndComplete();
            clearInterval(this.timer);
        }
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
    }

    async markLessonUnlockedAndComplete() {
        const {
            success: completeSuccess,
            message: completeMessage,
        } = await apiCall(
            'POST',
            `/users/lessons/${this.props.currentLesson.state._id}/complete`
        );

        const {
            success: unlockSuccess,
            message: unlockMessage,
        } = await apiCall(
            'POST',
            `/users/lessons/${this.props.currentLesson.state._id}/next`
        );

        if (unlockSuccess && completeSuccess) {
            this.props.setUpdateCoursePercentage(true);
            this.props.setCompletedLessons(this.props.currentLesson.state._id);
            this.props.setUnlockedLessons(this.state.nextLesson._id);
            this.setNextLesson();
        } else {
            this.setNextLesson();
        }
    }

    async getPrevLesson() {
        const prevLessonIndex = this.props.currentLesson.state.menuIndex - 1;
        const prevChapterIndex =
            this.props.currentChapter.state.menuIndex > 0
                ? this.props.currentChapter.state.menuIndex - 1
                : 0;
        if (
            this.props.currentChapter.state.menuIndex != 0 ||
            this.props.currentLesson.state.menuIndex != 0
        ) {
            if (prevLessonIndex >= 0) {
                // if not first lesson
                if (
                    'unlocked' in
                        this.props.allLessons.state[prevLessonIndex] ||
                    this.props.allLessons.state[prevLessonIndex]
                        .conditionStatement == 'ANY_TIME'
                ) {
                    this.props.history.push(
                        `/user/course/${this.props.currentCourse.state._id}/chapters/${this.props.currentChapter.state._id}/lesson/${this.props.allLessons.state[prevLessonIndex]._id}`
                    );
                }
            } else if (prevLessonIndex < 0) {
                // first lesson
                const prevChapterId = this.props.allChapters.state[
                    prevChapterIndex
                ]._id;

                const {
                    response: lessons,
                    success: foundAllChapterLessons,
                } = await apiCall(
                    'GET',
                    `/users/chapters/${prevChapterId}/lessons`
                );
                const lastLessonIndex = lessons.lessons.length - 1;
                if (
                    (foundAllChapterLessons &&
                        'unlocked' in lessons.lessons[lastLessonIndex]) ||
                    lessons.lessons[lastLessonIndex].conditionStatement ==
                        'ANY_TIME'
                ) {
                    this.props.setCurrentChapter(
                        this.props.allChapters.state[prevChapterIndex]
                    );
                    this.props.setUpdateCourseMenuLessons(prevChapterId);
                    this.props.setUnlockedLessons(
                        lessons.lessons[lastLessonIndex]._id
                    );
                    this.props.history.push(
                        `/user/course/${this.props.currentCourse.state._id}/chapters/${prevChapterId}/lesson/${lessons.lessons[lastLessonIndex]._id}`
                    );
                }
            }
        }
    }

    async getNextLesson() {
        const nextLessonIndex = this.props.currentLesson.state.menuIndex + 1;
        const lastLessonIndex = this.props.allLessons.state.length;
        const nextChapterIndex = this.props.currentChapter.state.menuIndex + 1;
        const lastChapterIndex = this.props.allChapters.state.length;
        const nextChapterId =
            nextChapterIndex == lastChapterIndex
                ? this.props.allChapters.state[
                      this.props.currentChapter.state.menuIndex
                  ]._id
                : this.props.allChapters.state[nextChapterIndex]._id;

        if (!('completed' in this.props.currentLesson.state)) {
            if (this.state.seconds <= 0) {
                await apiCall(
                    'POST',
                    `/users/lessons/${this.props.currentLesson.state._id}/complete`
                );
                this.props.setCompletedLessons(
                    this.props.currentLesson.state._id
                );
            } else {
                await this.showHideRequireTimeShield();
            }
        }
        let markedUnlocked = false;
        if (!('unlocked' in this.state.nextLesson)) {
            const { success, message } = await apiCall(
                'POST',
                `/users/lessons/${this.props.currentLesson.state._id}/next`
            );

            markedUnlocked = success;
        } else {
            markedUnlocked = true;
        }

        if (markedUnlocked && !this.state.requiredTimeShield) {
            if (nextLessonIndex < lastLessonIndex) {
                this.props.history.push(
                    `/user/course/${this.props.currentCourse.state._id}/chapters/${this.props.currentChapter.state._id}/lesson/${this.props.allLessons.state[nextLessonIndex]._id}`
                );
                this.props.setUnlockedLessons(
                    this.props.allLessons.state[nextLessonIndex]._id
                );
            } else if (nextChapterIndex < lastChapterIndex) {
                this.props.setUpdateCourseMenuLessons(
                    this.props.allChapters.state[nextChapterIndex]._id
                );
                this.props.setCurrentChapter(
                    this.props.allChapters.state[nextChapterIndex]
                );
                const {
                    response: lessons,
                    success: foundAllChapterLessons,
                } = await apiCall(
                    'GET',
                    `/users/chapters/${nextChapterId}/lessons`
                );
                if (foundAllChapterLessons) {
                    this.props.setUnlockedLessons(lessons.lessons[0]._id);
                    this.props.history.push(
                        `/user/course/${this.props.currentCourse.state._id}/chapters/${nextChapterId}/lesson/${lessons.lessons[0]._id}`
                    );
                }
            }
        } else {
            // console.log(markedUnlockedMessage);
        }
    }

    showHideRequireTimeShield() {
        this.setState({
            requiredTimeShield: !this.state.requiredTimeShield,
        });
    }

    render() {
        const inactiveDuration = Math.floor(
            (Date.now() - this.props.lastSeen.state) / 60000
        );
        if (
            this.props.currentLessonLayout.state == 'PAGE' &&
            Object.keys(this.props.currentLesson).length > 0 &&
            Object.keys(this.props.currentChapter).length > 0
        ) {
            return (
                <div className='page-layout-slider'>
                    <Modal
                        size='lg'
                        show={this.props.newCoursePopUp.state}
                        onHide={() => this.props.setNewCoursePopUp(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enrollment Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Thank you for enrolling on this course. You can
                                now start learning
                            </p>
                        </Modal.Body>
                    </Modal>
                    <RequiredTimeShield
                        getNextLesson={() => this.getNextLesson()}
                        showHideRequireTimeShield={() =>
                            this.showHideRequireTimeShield()
                        }
                        requiredTimeShield={this.state.requiredTimeShield}
                    />
                    <Modal
                        size='lg'
                        show={this.state.showInactivityModal}
                        onHide={() =>
                            this.setState({ showInactivityModal: false })
                        }>
                        <Modal.Header closeButton>
                            <Modal.Title>You are inactive</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                You have been inactive for {inactiveDuration}{' '}
                                minutes. Please close to resume your learning.
                            </p>
                        </Modal.Body>
                    </Modal>
                    <UserTracker
                        inactiveTime={
                            this.props.currentCourse.state.inactiveTime
                        }
                    />
                    <div className='slider-info-desk-page'>
                        <div className='slider-info'>
                            <span style={{ display: 'inline-block' }}>
                                {this.props.currentChapter.state.title}
                            </span>

                            <IconContext.Provider
                                value={{ color: '#cecece', size: '28px' }}>
                                <div style={{ display: 'inline-block' }}>
                                    <RiArrowRightSLine />
                                </div>
                            </IconContext.Provider>
                            <span style={{ display: 'inline-block' }}>
                                {this.props.currentLesson.state.title}
                            </span>
                        </div>
                    </div>
                    <CardSelector />
                    <div className='page-lesson-footer'>
                        <div className='page-lesson-footer-info'>
                            <div className='prev-lesson-button'>
                                <button
                                    className='bd rmxn'
                                    onClick={() => this.getPrevLesson()}>
                                    Previous
                                </button>
                            </div>
                            <div className='time-remaining'>
                                {this.props.currentLesson.state.requiredTime *
                                    60 >
                                    0 &&
                                !('completed' in this.props.currentLesson.state)
                                    ? [
                                          <span key='span-1' className='label'>
                                              Lesson time remaining &nbsp;
                                          </span>,
                                          <span key='span-2'>
                                              {this.state.time.h}:
                                              {this.state.time.m}:
                                              {this.state.time.s}
                                          </span>,
                                      ]
                                    : ''}
                            </div>
                            <div className='next-lesson-button'>
                                {'unlocked' in this.state.nextLesson ||
                                this.props.currentLesson.state.requiredTime <
                                    1 ? (
                                    <button
                                        className='bp rmxn'
                                        onClick={() => this.getNextLesson()}>
                                        Next
                                    </button>
                                ) : (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id='tooltip-disabled'>
                                                Complete the lesson required
                                                lesson time to continue
                                            </Tooltip>
                                        }>
                                        <span className='d-inline-block'>
                                            <button
                                                className='bd rmxn inactive'
                                                disabled
                                                onClick={() =>
                                                    this.getNextLesson()
                                                }>
                                                Next
                                            </button>
                                        </span>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}
const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const {
        allLessons,
        currentLesson,
        currentChapter,
        currentCourse,
        newCoursePopUp,
        allChapters,
        currentLessonLayout,
        updateCourseMenuLessons,
        unlockedLessons,
        userInactive,
        lastSeen,
    } = state; // inside curly braces we have the name of the state we want
    return {
        allLessons,
        currentLesson,
        currentChapter,
        currentCourse,
        newCoursePopUp,
        allChapters,
        currentLessonLayout,
        updateCourseMenuLessons,
        unlockedLessons,
        userInactive,
        lastSeen,
    };
};
const mapDispatchToProps = {
    setCurrentLesson,
    setCurrentChapter,
    setUnlockedLessons,
    setCompletedLessons,
    setNewCoursePopUp,
    setUpdateCoursePercentage,
    setUpdateCourseMenuLessons,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(LessonSlider));
