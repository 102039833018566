import React, { Component } from 'react';
import { Form, Image } from 'react-bootstrap';
import DocumentViewer from '../User/DocumentViewer';

export default class FileUpload extends Component {
    state = {
        fileVal: '',
    };

    handleFileChange = (event) => {
        if (event.target.value) {
            const file = event.target.files[0];
            const url = URL.createObjectURL(file);
            this.setState({
                fileVal: event.target.value,
            });
            this.props.handleFileChange(this.props.type, url, file);
        }
    };

    render() {
        return (
            <div>
                <Form.Label htmlFor={this.props.id}>
                    {this.props.label ??
                        this.props.type[0].toUpperCase() +
                            this.props.type.substr(1).toLowerCase()}
                </Form.Label>
                <Form.File
                    required={this.props.url ? false : true}
                    accept={
                        this.props.type === 'image'
                            ? '.png,.jpg'
                            : this.props.type === 'audio'
                            ? '.mp3,.wav,.m4a,.mp4,.wma,.aac'
                            : this.props.type === 'video'
                            ? '.mp4,.mov,.wmv,.flv,.avi,.mkv,.webm'
                            : this.props.type === 'document'
                            ? '.doc, .pdf'
                            : ''
                    }
                    id={this.props.id}
                    name={this.props.name}
                    onChange={this.handleFileChange}
                    disabled={this.props.disabled}
                />
                <br />
                {this.props.url && this.props.type === 'image' ? (
                    <Image
                        src={this.props.url}
                        style={{ height: '155px', objectFit: 'cover' }}
                    />
                ) : (
                    <></>
                )}

                {this.props.url && this.props.type === 'audio' ? (
                    <audio controls='controls' src={this.props.url} />
                ) : (
                    <></>
                )}

                {this.props.url && this.props.type === 'video' ? (
                    <video
                        height='200'
                        width='300'
                        controls='controls'
                        src={this.props.url}
                    />
                ) : (
                    <></>
                )}

                {this.props.url && this.props.type === 'document' ? (
                    <DocumentViewer document={this.props.url} />
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
