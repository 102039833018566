import './Admin.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../layouts/Admin';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { Messages } from './Messages';
import { Users } from './Users';
import { Courses } from './Courses';
import { Packages } from './Packages';
import { CoreLibrary } from './CoreLibrary';
import apiCall from '../../helpers/apiCall';
import { Quizzes } from './Quizzes';
import { Invoices } from './Invoices';
import { Settings } from './Settings';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Sessions } from './Sessions';

class Admin extends Component {
    render() {
        const breadcrumb = Object.keys(this.props.breadcrumb);
        return (
            <AdminLayout>
                <ul id='breadcrumb'>
                    <li>
                        <Link to='/admin'>Dashboard</Link>
                        {breadcrumb && breadcrumb.length > 0 && (
                            <Fa icon={faChevronRight} />
                        )}
                    </li>
                    {breadcrumb.map((path, index) => {
                        return (
                            <li key={path}>
                                <Link to={path}>
                                    {this.props.breadcrumb[path]}
                                </Link>
                                {index !== breadcrumb.length - 1 && (
                                    <Fa icon={faChevronRight} />
                                )}
                            </li>
                        );
                    })}
                </ul>
                <Switch>
                    <Route path='/admin/dashboard' component={Dashboard} />
                    <Route path='/admin/sessions' component={Sessions} />
                    <Route path='/admin/settings' component={Settings} />
                    <Route path='/admin/messages' component={Messages} />
                    <Route path='/admin/users' component={Users} />
                    <Route path='/admin/quizzes' component={Quizzes} />
                    <Route path='/admin/courses' component={Courses} />
                    <Route path='/admin/invoices' component={Invoices} />
                    <Route path='/admin/packages' component={Packages} />
                    <Route
                        path='/admin/core-libraries'
                        component={CoreLibrary}
                    />
                    <Redirect exact from='/admin' to='/admin/dashboard' />
                </Switch>
            </AdminLayout>
        );
    }
}

export default connect(
    (state) => {
        return {
            loggedIn: state.loggedIn,
            breadcrumb: state.globalBreadcrumb,
        };
    },
    {
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        pushBreadcrumbLink: (payload) => ({
            type: 'PUSH_BREADCRUMB_LINK',
            payload,
        }),
    }
)(Admin);
