// import rootReducer from './rootReducer';

// export default rootReducer;

// This file combines all the reducers we create. This keeps the reducers structure organised.

import { combineReducers } from 'redux';

import { loggedIn } from './accountReducer';
import { globalAlert, globalBreadcrumb } from './globalReducer';
import {
    courseMenuOpen,
    updateCourseMenuLessons,
    showCourseMenu,
    updateCoursePercentage,
    unlockedLessons,
    completedLessons,
} from './courseMenuReducer';
import {
    currentLesson,
    currentChapter,
    currentCourse,
    allLessons,
    allChapters,
    currentLessonCardIds,
    currentLessonLayout,
    newCoursePopUp,
} from './courseReducers';
import { userInactive, lastSeen } from './userReducer';

export default combineReducers({
    loggedIn,
    globalAlert,
    globalBreadcrumb,
    currentLesson,
    currentChapter,
    currentCourse,
    showCourseMenu,
    newCoursePopUp,
    allLessons,
    allChapters,
    currentLessonCardIds,
    currentLessonLayout,
    courseMenuOpen,
    updateCourseMenuLessons,
    userInactive,
    lastSeen,
    unlockedLessons,
    completedLessons,
});
